/* eslint-disable no-undef */
import { useContext, createContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { constants } from '../utils/constants';
import { LoadingContext } from './loadingContext';
import { getMetadataContainerRecords, getSalesforceAppSettings, getSalesforceAppSettingsLegacy, processChromeExtLayoutConfig, processDependentInputs } from '../utils/configFunctions';
import {
  getTruncatedText, chromeDecodeUrl, createExternalIdSQLQuery, createSalesforceCategory, decodeHTMLString, getCurrentTab, getExtendedIds, getBase64FromBase64Url,
  getEventOutlookUid, isOfficeSetSupported, limitCharacters, parseGoogleItemData, parseGoogleItemData_Meeting, parseSalesforceRecordData, restructureHTML, runFuzzyMatch, updateThemeColor,
} from '../utils/helperFunctions';
import { getItemRestId, loadPromiseMailASynch, loadPromiseMeetingsASynch } from '../utils/outlookItemFunctions';
import Cookies from "js-cookie";
import ToastMessage from '../components/ToastMessage/ToastMessage';


const PageContext = createContext();
const chrome = window?.['chrome'];
let existingCase = null;
let dialog; // used for outlook message window
const trustedDomains = [
  "https://localhost:3000",
  "https://localhost:3006",
  "https://addin.apps.investorflow.com",
  "https://outlook.apps.investorflow.com",
  "https://addin-dev.apps.investorflow.com",
  "https://outlook-dev.apps.investorflow.com",
  "https://outlook.live.com",
  "self"
];

/**
 * @summary Acts a store to house functions and state needed across many components
 * @function
 * @namespace Context/context
 * @description used to house various functions and global state for components.
 * To use, import { useContext } from 'react, also import { PageContext } from this location.
 * Then declare any function or state needed here such as in example below;
 * @example
 * const { screenType, showUserMenu, themeColors, userMenuAnchorEl } = useContext(PageContext);
*/
const PageProvider = ({ children, isOfficeInitialized }) => {
  // console.log('from use context', children);
  const [autoPopStorage, setAutoPopStorage] = useState(null);
  const [attachmentsConfig, setAttachmentsConfig] = useState(null);
  const [caseConfig, setCaseConfig] = useState(null); //config for case
  const [caseId, setCaseId] = useState(null);
  const [caseSourceMap, setCaseSourceMap] = useState({});
  const [contactEmailFields, setContactEmailFields] = useState(null);
  const [recordTypeIdEmail, setRecordTypeIdEmail] = useState('');
  const [recordTypeIdMeeting, setRecordTypeIdMeeting] = useState('');
  const [copyItemCount, setCopyItemCount] = useState(0);
  const [copyStorage, setCopyStorage] = useState({});
  const [detectedEntities, setDetectedEntities] = useState(null);
  const [detectEntityIsRunning, setDetectEntityIsRunning] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(null);
  const [deleteLinkStorage, setDeleteLinkStorage] = useState({});
  const [exchangeIdSet, setExchangeIdSet] = useState();
  const [errorLogs, setErrorLogs] = useState([]);
  const [existingInteraction, setExistingInteraction] = useState(null);
  const [existingLinks, setExistingLinks] = useState(null);
  const [externalId, setExternalId] = useState(null);
  const [externalIdField, setExternalIdField] = useState(null);
  const [googleAuthToken, setGoogleAuthToken] = useState(null);
  const [googleAuthUser, setGoogleAuthUser] = useState(null);
  const [googleMessageId, setGoogleMessageId] = useState(null);
  const [interactionId, setInteractionId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isDraftMode, setIsDraftMode] = useState(false);
  const [isExchangeSystem, setIsExchangeSystem] = useState();
  const [isLoginScreenOpen, setIsLoginScreenOpen] = useState(true);
  const [isDebugPanelOpen, setIsDebugPanelOpen] = useState(false);
  const [linkStorage, setLinkStorage] = useState({});
  const [logType, setLogType] = useState(null);
  const [msAuthUser, setMsAuthUser] = useState(typeof Office !== 'undefined' ? Office?.context?.mailbox?.userProfile : null);
  const [orgInfo, setOrgInfo] = useState({}); //store additional org schema from config
  const [parentIdMap, setParentIdMap] = useState({});
  const [payload, setPayload] = useState(null); //usage: store email and meeting raw data
  const [pipeVersion, setPipeVersion] = useState();
  const [quickAddMenu, setQuickAddMenu] = useState({}); //usage: render create button options on intro screen
  const [relatedListConfigs, setRelatedListConfigs] = useState([]); //usage: render layout for related data on intro screen
  const [requiredFields, setRequiredFields] = useState([]); //usage: input fields marked required
  const [salesforceInstance, setSalesforceInstance] = useState(null);
  const [salesforceUser, setSalesforceUser] = useState(null);
  const [saveResultToast, setSaveResultToast] = useState(null);
  const [sections, setSections] = useState([]); //usage: render layout for log data screen
  const [selectAttachmentByDefault, setSelectAttachmentByDefault] = useState(false);
  const [screenType, setScreenType] = useState("intro");
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [sourceFieldMap, setSourceFieldMap] = useState({}); //usage: map payload headers to input fields from sections
  const [themeColors, setThemeColors] = useState({ primary: "#007bc2", secondary: "", tertiary: "" });
  const [updateSidepanel, setUpdateSidepanel] = useState(null)
  const [userCookieData, setUserCookieData] = useState();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [valueStorage, setValueStorage] = useState({}); //usage: store data to be inserted into Salesforce
  const logScreenBottom = useRef();

  const { setIsLoading, setIsLoadingGlobal } = useContext(LoadingContext);

  useEffect(() => {
    if (location.pathname === "/login-success") { setShowLoginSuccess(true); };
  }, []);

  useEffect(() => {
    // check for Auth on app load
    if (constants.EMAIL_CLIENT === 'outlook' && isOfficeInitialized) {
      const officeProfile = Office?.context?.mailbox?.userProfile || {};
      if (isOfficeSetSupported('1.5') && Office.context.mailbox?.getCallbackTokenAsync) {
        officeProfile.account_type = Office?.context?.mailbox?.userProfile?.accountType || "unavailable";
        officeProfile.diplay_name = Office?.context?.mailbox?.userProfile?.displayName;
        officeProfile.email = Office?.context?.mailbox?.userProfile?.emailAddress;
        officeProfile.ewsUrl = Office.context.mailbox?.ewsUrl || "unavailable";
        officeProfile.hostname = Office.context.mailbox?.diagnostics?.hostName;
        officeProfile.PermissionLevel = Office.context?.mailbox?.initialData?.permissionLevel
        officeProfile.time_zone = Office?.context?.mailbox?.userProfile?.timeZone;
        setMsAuthUser(officeProfile);
        setIsExchangeSystem((officeProfile === 'enterprise') ? true : false);
      } else {
        setMsAuthUser(officeProfile);
      };
      // console.log(Office?.context?.mailbox?.userProfile);
      const investorflowCookie = localStorage.getItem('_investorflow');
      if (userCookieData !== investorflowCookie) {
        setUserCookieData(investorflowCookie);
      };
    } else {
      checkForGoogleAuth();
    };
    checkForSalesforceAuth();
  }, [isOfficeInitialized, userCookieData]);

  // To make sure we will re-render the email value only in case updated for chrome
  useEffect(() => {
    if (constants.EMAIL_CLIENT === 'gmail' && logType === 'email' && updateSidepanel !== null) {
      logEmailValues(updateSidepanel);
    };
  }, [updateSidepanel]);

  useEffect(() => {
    if ((googleAuthToken || msAuthUser) && salesforceUser) {
      setIsConnected(true);
      setIsLoginScreenOpen(false);
      setSaveResultToast(false);
      if (googleAuthToken) {
        if (!chrome.tabs.onUpdated.hasListener(chromeAddTabChangeListenerWithToken)) {
          chrome.tabs.onUpdated.addListener(chromeAddTabChangeListenerWithToken);
        };
        if (!chrome.tabs.onUpdated.hasListener(retrieveGoogleMessageIdFromSidePanel)) {
          chrome.runtime.onMessage.addListener(retrieveGoogleMessageIdFromSidePanel);
        };
      };
      initializeConfigurations();
    } else {
      if (googleAuthToken) {
        chrome.tabs.onUpdated.removeListener(chromeAddTabChangeListenerWithToken);
        chrome.runtime.onMessage.removeListener(retrieveGoogleMessageIdFromSidePanel);
      };
      setIsConnected(false);
      setShowUserMenu(false);
      // setScreenType("intro");
      setIsLoginScreenOpen(true);
    };
    return () => { };
  }, [googleAuthToken, msAuthUser, salesforceUser]);

  useEffect(() => {
    const saveTabId = async () => {
      const tab = await getCurrentTab();
      setCurrentTabId(tab?.id);

      if (tab?.url?.includes('https://mail.google.com')) {
        setLogType('email');
      } else if (tab?.url?.includes('https://calendar.google.com')) {
        setLogType('meeting');
      } else {
        // for now do nothing if not in gmail or google calendar
      };
    };

    const saveOutlookId = () => {
      const item = Office?.context?.mailbox?.item;
      if (!item?.itemId && item?.itemType === 'appointment') {
        if (Office.context.mailbox?.item?.saveAsync) {
          Office.context.mailbox.item.saveAsync(function (result) {
            setCurrentTabId(result.value);
          });
        } else {
          logError({
            functionName: 'saveOutlookId',
            error: 'Outlook failed to retrieve itemID - MS bug',
            additionalNotes: 'https://github.com/OfficeDev/office-js/issues/4330',
            displayedToUser: false,
            level: 'error'
          });
          console.log('Outlook failed to retrieve itemID - MS bug');
        };
      } else {
        setCurrentTabId(item?.itemId);
      };
      if (item?.itemType === 'message') {
        setLogType('email');
      } else if (item?.itemType === 'appointment') {
        setLogType('meeting');
      };
    };

    if (constants.EMAIL_CLIENT === 'outlook' && isOfficeInitialized) {
      saveOutlookId();
    } else {
      saveTabId();
    };
  }, [isOfficeInitialized, isExchangeSystem]);

  useEffect(() => {
    if (((salesforceUser?.accessToken || salesforceUser?.token)) && currentTabId) {
      if (screenType === "intro" && !!externalId && !!payload) {
        if (salesforceUser?.entityRecognition) {
          runEntityDetectionService();
        };
        if (sections?.length > 0) {
          retrieveExistingInteraction();
        };
        if (caseConfig?.sections?.length > 0) {
          retrieveExistingCase();
        };
      };
    };
  }, [externalId, payload, salesforceUser, screenType, sections]);

  useEffect(() => {
    const newInteractionItem = (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        // failed to get interaction from Office
        logError({
          functionName: 'newInteractionItem',
          error: result,
          additionalNotes: 'failed to get new item from Office.js',
          displayedToUser: false,
          level: 'error'
        });
      } else {
        if (!!sections) {
          loadNewItem(result);
        };
      };
    };

    if (constants.EMAIL_CLIENT === 'outlook' && isOfficeInitialized && salesforceUser) {
      Office?.context?.mailbox?.addHandlerAsync(Office?.EventType?.ItemChanged, loadNewItem, newInteractionItem);
    };

    return () => { isOfficeInitialized && Office?.context?.mailbox?.removeHandlerAsync(Office.EventType.ItemChanged, newInteractionItem); };
  }, [isOfficeInitialized, salesforceUser]);

  useEffect(() => {
    const runDebugDiognostics = () => {
      const host = Office?.context?.mailbox?.diagnostics?.hostName;
      const contextInfo = Office.context.diagnostics;
      // console.log('Office contextInfo', contextInfo);
      // console.log('User Office Profile', Office?.context?.mailbox?.userProfile);
      // console.log('Office.context.mailbox', Office.context.mailbox)
      if (host === 'Outlook') {
        const promise = document.hasStorageAccess();
        promise.then(
          function (hasAccess) {
            // console.log('has access', host, hasAccess);
          },
          function (reason) {
            // console.log('no storage access reason', host, reason);
          }
        );
      };
      if (Office.context?.mailbox?.item?.displayReplyForm !== undefined) {
        // console.log('read mode');
      } else {
        // console.log('compose mode');
      };
    };

    if (salesforceUser?.debugMode) {
      console.log('%cSalesforceUser', "color: cyan; font-size: 12px;", salesforceUser);
      if (constants.EMAIL_CLIENT === 'outlook' && isOfficeInitialized) {
        runDebugDiognostics();
      };
    };
  }, [isOfficeInitialized, salesforceUser]);

  /**
   * @summary Adds and removes an event listener for new cookies
   * @function
   * @memberof Context/context
   * @description Used only in chrome, adds an event listener for new sign in cookie coming from server.
   * After cookie is sent, verified and stored will then remove the event listener
   * @param {number} WindowID window id to delete after succesful sign in
  */
  const addCookieListener = (windowID) => {
    chrome.cookies.onChanged.addListener(
      function getThenEatCookie(changeInfo) {
        if (changeInfo?.cookie?.name === '_investorflow') {
          // console.log('setting cookie', changeInfo?.cookie?.value);
          if (changeInfo?.removed === false) {
            const data = JSON.parse(changeInfo?.cookie?.value);
            /**
             * Code Description:
             * This block of code is designed to interact with Gmail's API, specifically for managing Gmail labels.
             * It includes functionality to find a specific label by name, load labels, create a new label if one doesn't exist,
             * and handle authentication and error scenarios.
             *
             * Permissions Required:
             * - "https://www.googleapis.com/auth/gmail.labels": This permission is necessary to create, access, and modify Gmail labels.
             *   It's used in this code to fetch existing labels and create new ones if needed.
             * - "https://www.googleapis.com/auth/gmail.modify": This permission allows for modifying Gmail messages and labels.
             *   While not directly used in the current implementation, it's required for potential future expansions where message modification might be needed.
             *
             * Current Status:
             * As of now, this code is disabled. This decision was made because there are concerns about the required permissions.
             *
             * Future Considerations:
             * If there's a need to re-enable this functionality, ensure that the required permissions are included in the manifest file.
             * Also, review and test the code to ensure it aligns with the current Gmail API standards and our application's requirements.
             *
             * NOTE: Before re-enabling, consider any changes to the Gmail API and update the code accordingly.
            */
            // data?.category && checkForGmailLabel(data?.category, googleAuthToken, data?.categoryColor);
            chrome.storage.session.set({ investorflow: data }).then(() => {
              setSalesforceUser(data);
              if (googleAuthUser) {
                setIsLoginScreenOpen(false);
              };
            });
            const localData = {
              account: `${data.account}.com`,
              instance: data.instance
            };
            chrome.storage.local.set({ investorflow: localData });
            // Remove listner once we have what we need
            chrome.cookies.onChanged.removeListener(getThenEatCookie);
            // remove window created by salesforce login
            chrome.windows.getAll({ populate: true, windowTypes: ["popup"] }, (popupWindows) => {
              popupWindows.forEach(item => {
                if (item?.tabs?.[0]?.pendingUrl?.includes('apps.investorflow.com') || item?.tabs?.[0]?.url.includes('apps.investorflow.com')) {
                  chrome.windows.remove(item.id);
                };
              })
            });
          };
        };
      }
    );
  };

  const buildExistingCaseData = (newData) => {
    // console.log('existingCase', existingCase);
    if (!!existingCase && caseConfig?.sections) {
      let updateSections = false;
      caseConfig.sections.forEach(sectionConfig => {
        sectionConfig.inputFields.forEach(inputConfig => {
          if (existingCase[inputConfig.targetField] != undefined && typeof existingCase[inputConfig.targetField] === 'boolean') {
            const item = {
              sectionId: sectionConfig.id,
              inputId: inputConfig.id,
              targetField: inputConfig.targetField,
              type: inputConfig.type,
              value: !!existingCase[inputConfig.targetField]
            }
            newData.push(item);
          } else if (existingCase[inputConfig.targetField]) {
            if (inputConfig.type === 'lookup') {
              inputConfig.defaultValue = existingCase[inputConfig.targetField];
              updateSections = true;
            } else if (inputConfig.type === 'picklist') {
              // Process RecordType
              inputConfig.recordTypeId = existingCase.RecordTypeId;
              inputConfig.defaultValue = existingCase[inputConfig.targetField];
            } else {
              const item = {
                sectionId: sectionConfig.id,
                inputId: inputConfig.id,
                targetField: inputConfig.targetField,
                type: inputConfig.type,
                value: parseSalesforceRecordData(existingCase[inputConfig.targetField], inputConfig)
              }
              newData.push(item);
            };
          };
        });
      });

      if (updateSections) {
        setCaseConfig(caseConfig);
      };
    };
  };

  const buildExistingInteractionData = (newData) => {
    if (!!existingInteraction && sections) {
      let updateSections = false;
      sections.forEach(sectionConfig => {
        sectionConfig.inputFields.forEach(inputConfig => {
          if (inputConfig.targetObject.indexOf('Activity__c') >= 0) {
            if (existingInteraction[inputConfig.targetField] != undefined && typeof existingInteraction[inputConfig.targetField] === 'boolean') {
              const item = {
                sectionId: sectionConfig.id,
                inputId: inputConfig.id,
                targetField: inputConfig.targetField,
                type: inputConfig.type,
                value: !!existingInteraction[inputConfig.targetField]
              }
              newData.push(item);
            } else if (existingInteraction[inputConfig.targetField]) {
              if (inputConfig.type === 'lookup') {
                inputConfig.defaultValue = existingInteraction[inputConfig.targetField];
                updateSections = true;
              } else {
                const item = {
                  sectionId: sectionConfig.id,
                  inputId: inputConfig.id,
                  targetField: inputConfig.targetField,
                  type: inputConfig.type,
                  value: parseSalesforceRecordData(existingInteraction[inputConfig.targetField], inputConfig)
                }
                newData.push(item);
              };
            };
          };
        });
      });

      if (updateSections) {
        // console.log("buildExistingInteractionData", sections);
        setSections(sections);
      };
    };
  };

  const buildGoogleEmailData = (rawData) => {
    // salesforceUser?.debugMode && console.log('email raw data', rawData);
    setDetectedEntities(null);
    if (rawData) {
      const messageId = rawData?.payload?.headers?.filter((item) => (item?.name === 'Message-Id' || item?.name === 'Message-ID'))?.[0]?.value?.replace('<', '')?.replace('>', '');
      let externalIdFields = [{ 'CT_PE__Exchange_Internet_Message_Id_255__c': messageId?.substring(0, 255) }, { 'CT_PE__Exchange_Item_Id_255__c': rawData?.id?.substring(0, 255) }, { 'CT_PE__Exchange_Thread_Id_255__c': rawData?.threadId?.substring(0, 255) }];
      if (messageId?.length > 255) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_Internet_Message_Id__c': messageId }] };
      if (rawData?.id?.length > 255) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_Item_Id__c': rawData?.id }] };
      if (rawData?.threadId?.length > 255) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_Thread_Id__c': rawData?.threadId }] };
      if (constants.EMAIL_CLIENT === 'outlook' && salesforceUser?.rivaEnabled) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_Composite_Id__c': '' }] };
      // console.log('externalIdFields', externalIdFields);
      setPayload(rawData.payload);
      setExternalId(messageId ? messageId : rawData.id);
      setExchangeIdSet(externalIdFields);
    } else {
      setExternalId(null);
      setExchangeIdSet(null);
      setPayload(null);
    };
  };

  const buildGoogleMeetingData = (rawData) => {
    // salesforceUser?.debugMode && console.log('event raw data', rawData);
    setDetectedEntities(null);
    if (rawData) {
      let externalIdFields = [{ 'CT_PE__Exchange_iCalUID_255__c': rawData?.iCalUID?.substring(0, 255) }, { 'CT_PE__Exchange_Item_Id_255__c': rawData?.id?.substring(0, 255) }];
      if (rawData?.iCalUID?.length > 255) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_iCalUID__c': rawData?.iCalUID }] };
      if (rawData?.id?.length > 255) { externalIdFields = [...externalIdFields, { 'CT_PE__Exchange_Item_Id__c': rawData?.id }] };
      setExternalId(rawData?.iCalUID ? rawData?.iCalUID : rawData?.id);
      setExchangeIdSet(externalIdFields);
      setPayload(rawData);
    } else {
      setExternalId(null);
      setExchangeIdSet(null);
      setPayload(null);
    };
  };

  const buildMessageToSendToContentScript = async () => {
    const tab = await getCurrentTab();
    let message;
    if (tab?.url) {
      // console.log('tab?.url', tab?.url);
      if (tab.url.includes('https://mail.google.com')) {
        if (logType === 'email') {
          resetStorageData();
        };
        message = 'get-email-id';
        const parsedUrl = tab.url.split("#");
        if (parsedUrl[1] && parsedUrl[1]?.includes('/')) {
          if ((parsedUrl[1].startsWith('search/') || parsedUrl[1].startsWith('advanced-search/'))) {
            if (parsedUrl[1].split('/').length >= 3) {
              chromeGetLegacyId(tab.id, tab.url, message);
            } else {
              setUpdateSidepanel(null);
              setScreenType("intro");
            };
          } else if (parsedUrl[1].startsWith('label/')) {
            if (parsedUrl[1].split('/').length >= 3) {
              chromeGetLegacyId(tab.id, tab.url, message);
            } else {
              setUpdateSidepanel(null);
              setScreenType("intro");
            };
          } else {
            chromeGetLegacyId(tab.id, tab.url, message);
          };
        } else {
          setUpdateSidepanel(null);
          setScreenType("intro");
        };
      } else if (tab.url.includes('https://calendar.google.com')) {
        if (logType === 'meeting') {
          resetStorageData();
        };
        const parsedUrl = tab.url.split("eventedit");
        if (parsedUrl[1]?.includes('/')) {
          message = 'get-meeting-id';
          chromeGetLegacyId(tab.id, tab.url, message);
        } else {
          setUpdateSidepanel(null);
          setScreenType("intro");
        };
      };
    } else {
    };
  };

  /**
   * Checks Google authentication status and sets related state.
   * @function
   * @async
   * @memberof Context/context
   * @description Checks if the user is authenticated with Google and sets the authentication token and user info in the state.
  */
  const checkForGoogleAuth = async () => {
    chrome.identity.getAuthToken({ interactive: false }, function (token) {
      if (chrome.runtime.lastError) {
        return;
      } else {
        // if user is signed in then get user info
        chrome.identity.getProfileUserInfo({ accountStatus: 'ANY' }, function (user_info) {
          salesforceUser?.debugMode && console.log('%cGoogle user info', "color: cyan; font-size: 12px;", user_info, token);
          if (user_info) {
            setGoogleAuthUser(user_info);
          };
          if (token) {
            setGoogleAuthToken(token);
          };
        });
        return token;
      };
    });
  };

  /**
   * Retrieves existing Salesforce authentication and updates state accordingly.
   * @function
   * @memberof Context/context
   * @description Checks local storage or session for existing Salesforce authentication data and updates the state if present.
  */
  const checkForSalesforceAuth = () => {
    if (constants.EMAIL_CLIENT === 'outlook') {
      const localUser = localStorage.getItem('_investorflow');
      if (localUser) {
        // setSalesforceUser(localUser);
        // setIsLoginScreenOpen(false);
      } else {
        console.log('%cLogged out / no user', "color: cyan; font-size: 12px;");
      };
      if (userCookieData) {
        try {
          const parsedUser = JSON.parse(userCookieData);
          setSalesforceUser(parsedUser);
          setIsLoginScreenOpen(false);
          if (isOfficeSetSupported('1.8')) {
            parsedUser?.category && createSalesforceCategory(parsedUser?.category, parsedUser?.categoryColor ? parsedUser?.categoryColor : null);
          };
        } catch (error) {
          logError({
            functionName: 'checkForSalesforceAuth',
            error: error,
            additionalNotes: '',
            displayedToUser: false,
            level: 'error'
          });
          // alsready an object so just set it
          setSalesforceUser(userCookieData);
          setIsLoginScreenOpen(false);
        };
      };
    } else {
      chrome.storage.session.get(["investorflow"]).then((result) => {
        if (result?.investorflow) {
          // console.log("already signed into salesforce", result);
          setSalesforceUser(result?.investorflow);
          setIsLoginScreenOpen(false);
          /**
           * Code Description:
           * This block of code is designed to interact with Gmail's API, specifically for managing Gmail labels.
           * It includes functionality to find a specific label by name, load labels, create a new label if one doesn't exist,
           * and handle authentication and error scenarios.
           *
           * Permissions Required:
           * - "https://www.googleapis.com/auth/gmail.labels": This permission is necessary to create, access, and modify Gmail labels.
           *   It's used in this code to fetch existing labels and create new ones if needed.
           * - "https://www.googleapis.com/auth/gmail.modify": This permission allows for modifying Gmail messages and labels.
           *   While not directly used in the current implementation, it's required for potential future expansions where message modification might be needed.
           *
           * Current Status:
           * As of now, this code is disabled. This decision was made because there are concerns about the required permissions.
           *
           * Future Considerations:
           * If there's a need to re-enable this functionality, ensure that the required permissions are included in the manifest file.
           * Also, review and test the code to ensure it aligns with the current Gmail API standards and our application's requirements.
           *
           * NOTE: Before re-enabling, consider any changes to the Gmail API and update the code accordingly.
          */
          // result?.investorflow?.category && checkForGmailLabel(result?.investorflow?.category, googleAuthToken, result?.investorflow?.categoryColor);
        } else {
          chrome.storage.local.get(["investorflow"]).then((data) => {
            if (data?.investorflow) {
              if (!salesforceUser) {
                salesforceLoginUser(data?.investorflow?.account, data?.investorflow?.instance);
              };
            };
          });
        }
      });
    };
  };

  const checkIncomingMessage = (data) => {
    // console.log('adding window send message: data:', data);
    if (trustedDomains.includes(data.origin)) {
      if (data?.data) {
        // console.log('about to process data?.data', data.data);
        processMessage(data?.data);
      } else {
        // console.log('about to process data?.message)...', data?.message);
        processMessage(data?.message);
      };
    };
  };

  const checkSecondaryCalendars = async (allCalendars, id) => {
    // console.log('from check secondary calendar', id, allCalendars);
    const headers = { headers: { 'Authorization': 'Bearer ' + googleAuthToken, 'Content-Type': 'application/json' } };
    let retryCount = 0;
    let sucess = false;
    let privateEvent = null;

    while (!sucess && retryCount < allCalendars?.length) {
      try {
        const url = `${constants.REACT_CALENDAR_ENDPOINT}/${allCalendars?.[retryCount]?.id}/events/${id}`;
        const response = await axios.get(url, headers);
        if (response?.data?.visibility === "private") {
          privateEvent = response.data;
          retryCount++;
        } else {
          sucess = true;
          buildGoogleMeetingData(response.data);
        };
      } catch (error) {
        logError({
          functionName: 'checkSecondaryCalendars',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        // if (error?.response?.data?.error?.code === 403 && retryCount < allCalendars?.length) {
        if (retryCount === (allCalendars?.length - 1)) {
          console.log('error fetching id for each calendar from google', error);
          sucess = true;
          if (privateEvent) {
            buildGoogleMeetingData(privateEvent);
            setSaveResultToast(
              <ToastMessage message={'Google is not giving full calendar aceess to this event, some data man need to be manually logged'} severity={'info'} title={'Private Event'} type={'alert'} />
            );
          } else {
            setIsLoginScreenOpen(true);
            setSaveResultToast(
              <ToastMessage message={'You are currently logged in with another account. Please use the "Google Workspace Logout" button to log back in using this one.'} severity={'warning'} title={'Failed to verify credentials'} type={'alert'} />
            );
          };
        } else {
          retryCount++;
        };
      };
    };
  };

  const chromeAddTabChangeListenerWithToken = (tabId, changeInfo, tab) => {
    if (changeInfo?.title && tab?.status === "complete") {
      // console.log('tabId, currentTabId', tabId, currentTabId);
      if (tabId === currentTabId) {
        buildMessageToSendToContentScript();
      };
    };
    return true;
  };

  const chromeGetLegacyId = (id, url, message) => {
    chrome.tabs.sendMessage(id, { msg: message, url: url }, (res) => {
      if (chrome.runtime.lastError) {
        // console.warn('error message from context! we will fix it later', chrome.runtime.lastError.message);
        return;
      };
      if (res?.msg) {
        if (res?.msg === 'no ID found in current tab') {
          // console.log('no id found');
        } else {
          // in case user has calendar and email tabs open only grab new data if url matches log type
          // console.log('res?.msg', res?.msg);
          if (logType === 'email' && message === 'get-email-id') {
            logEmailValues(res.msg);
          } else if (logType === 'meeting' && message === 'get-meeting-id') {
            const newExternalId = chromeDecodeUrl(res.msg?.eventId);
            // console.log(newExternalId);
            getGoogleCalendarEvent(newExternalId, res.msg?.calendarId);
          };
        };
      };
    });
  };

  const fetchGoogleCalendars = async (id) => {
    const headers = { headers: { 'Authorization': 'Bearer ' + googleAuthToken, 'Content-Type': 'application/json' } };
    try {
      let allCalendars = [];
      const calendarListApiUrl = `https://www.googleapis.com/calendar/v3/users/me/calendarList`;
      const response = await axios.get(calendarListApiUrl, headers);
      const result = await response.data;
      // console.log('response for calendar list', result);
      await result?.items?.forEach((item) => {
        allCalendars.push(item)
      });
      checkSecondaryCalendars(allCalendars, id);
    } catch (error) {
      logError({
        functionName: 'fetchGoogleCalendars',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.log('error fetching calendarlist from google', error);
      setIsLoginScreenOpen(true);
      setSaveResultToast(
        <ToastMessage message={'You are currently logged in with another account. Please use the "Google Workspace Logout" button to log back in using this one.'} severity={'warning'} title={'Failed to verify credentials'} type={'alert'} />
      );
    };
  };

  const getGoogleCalendarEvent = async (id, calId) => {
    let token = googleAuthToken;
    // console.log('getGoogleCalendarEvent', googleAuthUser);
    const calendarId = calId ? calId : googleAuthUser?.email;
    const headers = {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    };
    const url = `${constants.REACT_CALENDAR_ENDPOINT}/${calendarId}/events/${id}`;
    try {
      const response = await axios.get(url, headers);
      if (response?.data?.visibility === "private") {
        fetchGoogleCalendars(id);
      } else {
        buildGoogleMeetingData(response.data);
      };
    } catch (error) {
      logError({
        functionName: 'getGoogleCalendarEvent',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      // console.warn('error fetching email from google', error);
      if (error?.response?.status === 401) {
        const newToken = await checkForGoogleAuth();
        if (newToken) {
          try {
            token = newToken;
            const newResponse = await axios.get(url, headers);
            buildGoogleMeetingData(newResponse?.data);
          } catch (err) {
            // console.warn('failed to refresh google auth token', err);
            setIsLoginScreenOpen(true);
            setSaveResultToast(
              <ToastMessage message={'unable to authenticate please refresh page or sign in again'} severity={'warning'} title={'failed to verify credentials'} type={'alert'} />
            );
          };
        };
      } else if (error.response?.status === 404 || error.response?.status === 403) {
        fetchGoogleCalendars(id);
        // console.warn('response from google is 404 - this calendar with that id exists for this google account', error, '... checking other calendars');
      } else {
        console.warn('error calling calendar api for this event', error);
      };
    };
  };

  const getConfigData = async (interactionRecordTypeId, fePkgVersion) => {
    const fundEngineVersion = fePkgVersion || pipeVersion;

    let userSettingErrors;
    const sfAppSettings = fundEngineVersion > 13 ?
      await getSalesforceAppSettings({ ...salesforceUser, pipeVersion: fundEngineVersion })
      : await getSalesforceAppSettingsLegacy({ ...salesforceUser, pipeVersion: fundEngineVersion });
    if (JSON.stringify(sfAppSettings) === "{}") {
      setScreenType("intro");
      setIsLoginScreenOpen(true);
      setSaveResultToast(
        <ToastMessage message={'You do not have access to the RecordType Map custom setting, please reach out to an admin.'} severity={'error'} title={'Permission Error'} type={'alert'} />
      );
      return;
    };
    if (sfAppSettings?.error === 401) {
      return refreshSalesforceToken();
    } else {
      userSettingErrors = [];
      if (!sfAppSettings?.internal || !sfAppSettings?.external) {
        userSettingErrors.push("CT_UI__RecordType_Map__c");
      }
      if (userSettingErrors.length > 0) {
        setScreenType("intro");
        setIsLoginScreenOpen(true);
        setSaveResultToast(
          <ToastMessage
            message={
              <article>
                <div>
                  Email Add-In failed to retrieve the following settings:
                  <ul style={{ paddingInlineStart: "1rem", marginBlockStart: "0.5rem" }}>
                    {userSettingErrors.map((msg, index) => <li key={`${index}-alert`}>{msg}</li>)}
                  </ul>
                </div>
                <p>
                  Please reach out to an admin to update Salesforce configuration.
                </p>
              </article>
            }
            severity={'error'}
            title={"Configuration Error"}
            type={'alert'} />
        );
        return;
      } else {
        userSettingErrors = false;
        logError({
          functionName: 'pipeProfile',
          error: sfAppSettings?.pipeProfile,
          additionalNotes: 'current pipe profile config',
          displayedToUser: false,
          level: 'info'
        });
      };

      const isPipeSelectClause = fundEngineVersion > 13 ? `CT_PE__Pipe_Profile__c,` : '';
      const isPipeWhereClause = fundEngineVersion > 13 ? `+WHERE+CT_PE__Pipe_Profile__c='${sfAppSettings.pipeProfile}'` : `+WHERE+DeveloperName='${sfAppSettings.chromeExtName}'`;

      let soqlString = `SELECT+CT_PE__Dropdown_Menu_JSON__c,${isPipeSelectClause}`
        + `CT_PE__Log_Email_JSON__c,CT_PE__Log_Meeting_JSON__c,`
        + `CT_PE__New_Records_JSON__c,CT_PE__Related_Lists_JSON__c,`
        + `CT_PE__Log_Case_JSON__c,CT_PE__Describe_Field_Results_JSON__c`
        + `+FROM+CT_PE__Chrome_Extension_Layout__mdt`
        + `${isPipeWhereClause}`;
      const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
      const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };

      let response;
      try {
        response = await axios.get(url, optionsV3);
      } catch (error) {
        logError({
          functionName: 'getConfigData',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        if (error?.status === 401 || error?.response?.status === 401) {
          refreshSalesforceToken();
        } else if (error?.response?.status === 400 || !error?.response?.status) {
          userSettingErrors = true;
        };
      };

      let metaContainer;
      if (fundEngineVersion >= 16) {
        metaContainer = await getMetadataContainerRecords(salesforceUser);
      }

      if (response?.data?.records?.length > 0) {
        // process Chrome Extention Layout and return settings in the result object
        const contactObject = 'Contact';
        const result = processChromeExtLayoutConfig(response.data.records[0], sfAppSettings, logType, contactObject, interactionRecordTypeId, metaContainer);
        if (result.errors?.length > 0) {
          setSaveResultToast(
            <ToastMessage
              message={
                <article>
                  <div>
                    Addin failed to process the following settings from <b>{sfAppSettings.chromeExtName}</b>
                    <ul style={{ paddingInlineStart: "1rem", marginBlockStart: "0.5rem" }}>
                      {result.errors.map((msg, index) => <li key={`toast-${index}`}>{msg}</li>)}
                    </ul>
                  </div>
                  <p>
                    Please reach out to an admin to update this configuration.
                  </p>
                </article>
              }
              severity={'error'}
              title={"Configuration Error"}
              type={'alert'} />
          );
        };
        if (sfAppSettings?.pipeProfile) { result.pipeProfile = sfAppSettings?.pipeProfile };
        salesforceUser?.debugMode && console.log("%cChrome Extension Layout", "color: cyan; font-size: 12px;", result);
        if (result.orgInfo) setOrgInfo(result.orgInfo);
        if (result.dfrMap?.miscellaneous?.theme) setThemeColors(updateThemeColor(themeColors, result.dfrMap.miscellaneous.theme));
        if (result.dfrMap?.miscellaneous?.emailFields) setContactEmailFields([...result.dfrMap.miscellaneous.emailFields]);
        if (result.themeColors) setThemeColors(updateThemeColor(themeColors, result.themeColors));
        if (result.caseConfig) setCaseConfig(result.caseConfig);
        if (result.externalIdField) setExternalIdField(result.externalIdField);
        if (result.sourceFieldMap) setSourceFieldMap(result.sourceFieldMap);
        if (result.caseSourceMap) setCaseSourceMap(result.caseSourceMap);
        if (result.sections) setSections(result.sections);
        if (result.requiredFields) setRequiredFields(result.requiredFields);
        if (result.attachmentsConfig) setAttachmentsConfig(result.attachmentsConfig);
        if (result.relatedListConfigs) setRelatedListConfigs(result.relatedListConfigs);
        if (result.quickAddMenu) setQuickAddMenu(result.quickAddMenu);
        setSelectAttachmentByDefault(!!result.selectAttachmentByDefault);

        if (constants.EMAIL_CLIENT !== 'outlook') {
          buildMessageToSendToContentScript();
        };

      } else {
        userSettingErrors = true;
      };

      if (userSettingErrors) {
        setScreenType("intro");
        setIsLoginScreenOpen(true);
        setSaveResultToast(
          <ToastMessage
            message={
              <div>
                <p>
                  Email Add-In failed to retrieve layout configurations for this Pipe Profile: <b>{sfAppSettings.pipeProfile}</b>.
                </p>
                <p>
                  Please reach out to an admin to update Email Add-In configuration in Salesforce.
                </p>
              </div>
            }
            severity={'error'}
            title={"Configuration Error"}
            type={'alert'} />
        );
      };
    };
  };

  const getGraphMailboxItem = async (type, id) => {
    const graphToken = await getGraphToken();
    if (graphToken) {
      let headers = {
        headers: {
          'Authorization': 'Bearer ' + graphToken,
          'Content-Type': 'application/json'
        }
      };
      try {
        const request = await axios.get(`${constants.REACT_SERVER_URL}/api/v2/graph/${type}/${id}`, headers);
        // console.log('request', request?.data);
        return request?.data;
      } catch (error) {
        console.log(`error fetching graph ${type}`, error);
      };
    } else {
      console.log('failed to fetch graphToken');
    };
  };

  const getGraphToken = async () => {
    const ssoOptions = {
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    };
    try {
      // Note that Office.auth.getAccessToken modifies the options parameter. Create a copy of the object to avoid modifying the original object.
      const options = JSON.parse(JSON.stringify(ssoOptions));
      const token = OfficeRuntime?.auth?.getAccessToken ? await OfficeRuntime.auth.getAccessToken(options) :  await Office.auth?.getAccessToken(options);
      console.log('token', token);
      return token;
    } catch (error) {
      console.log('error here', error.message);
      logError({
        functionName: 'getGraphToken fail',
        error: JSON.stringify(error),
        additionalNotes: JSON.stringify(Office.auth),
        displayedToUser: false,
        level: 'info'
      });
      return null;
    };
  };

  const getNewDataFromSourceMap_Email = (sfMap) => {
    const result = [];
    if (payload) {
      for (let sourceField in sfMap) {
        let rawValue = payload;
        let sourceFound = true;
        sourceField.split(".").forEach(field => {
          if (rawValue instanceof Array) {
            sourceFound = rawValue.some(item => {
              if (item.name.toLowerCase() === field.toLowerCase()) {
                rawValue = item.value;
                return true;
              }
              return false;
            });
          } else {
            rawValue = rawValue?.[field];
          }
        });
        if (sourceFound) {
          sfMap[sourceField].forEach(dataItem => {
            dataItem.value = parseGoogleItemData(rawValue, dataItem.type, sourceField);
            result.push(dataItem);
          });
        };
      };
      // for variation in email where data is in body only
      if (constants.EMAIL_CLIENT !== 'outlook' && payload?.body?.size !== 0) {
        sfMap.parts[0].value = Base64.decode(payload?.body?.data);
        result.push(sfMap.parts);
      };
    };
    return result;
  };

  const getNewDataFromSourceMap_Meeting = (sfMap) => {
    const result = [];
    if (payload) {
      let isAllDayEvent = !!payload.start?.date && !!payload.end?.date;
      // isAllDayEvent appears to be broken in outlook - need to make a different method
      if (constants.EMAIL_CLIENT === 'outlook') {
        if (payload.isAllDayEvent !== undefined && typeof payload.isAllDayEvent == 'boolean') {
          isAllDayEvent = payload.isAllDayEvent
        } else {
          const startTime = new Date(payload?.start);
          const endTime = new Date(payload?.end);
          const timeDiff = Math.abs(startTime.getTime() - endTime.getTime());
          isAllDayEvent = timeDiff >= 86400000 ? true : false
        };
      };
      for (let sourceField in sfMap) {
        if (isAllDayEvent && (sourceField.toLowerCase() === 'alldayevent' || sourceField.toLowerCase() === 'isalldayevent')) {
          sfMap[sourceField].forEach(dataItem => {
            dataItem.value = true;
            result.push(dataItem);
          });
        } else {
          let rawValue = payload;
          let sourceFound = true;
          sourceField.split(".").forEach(field => {
            if (rawValue instanceof Array) {
              //may need to consider usecase and logic for array
              //currently we don't have use case for this
              sourceFound = false;
            } else {
              rawValue = rawValue[field];
            };
          });

          if (sourceFound) {
            sfMap[sourceField].forEach(dataItem => {
              dataItem.value = parseGoogleItemData_Meeting(rawValue, dataItem.type);
              result.push(dataItem);
            });
          }
        }
      }
    }
    // console.log('result', result)
    return result;
  };

  const getOutlookRecordId = (item) => {
    if (item?.itemType === 'message') {
      const IMID = item?.internetMessageId?.replace('<', '')?.replace('>', '');
      // let externalIdFields = [{'CT_PE__Exchange_Internet_Message_Id_255__c': IMID?.substring(0, 255)},{'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255)}, {'CT_PE__Exchange_Thread_Id_255__c': item?.conversationId?.substring(0, 255)}];
      const externalIdFields = [
        { 'CT_PE__Exchange_Internet_Message_Id_255__c': IMID?.substring(0, 255) },
        { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) },
        { 'CT_PE__Exchange_Thread_Id_255__c': item?.conversationId?.substring(0, 255) },
        { 'CT_PE__Exchange_Internet_Message_Id__c': IMID },
        { 'CT_PE__Exchange_Item_Id__c': item?.itemId },
        { 'CT_PE__Exchange_Thread_Id__c': item?.conversationId },
        { 'CT_PE__Exchange_Composite_Id__c': IMID }
      ];
      try {
        Office.context.mailbox.item?.loadCustomPropertiesAsync(function (asyncResult) {
          let customProps = asyncResult.value;
          if (customProps) {
            const sfdcID = customProps.get('sfdcId');
            if (!sfdcID && !item?.internetMessageId && !item?.itemId && !item?.conversationId) {
              setIsDraftMode(true);
              setSaveResultToast(<ToastMessage message={"Saved interaction may not be seen by all users, please send email before saving"} severity={'info'} title={"Currently in draft mode"} type={'alert'} />);
              setExternalId(`draft-${new Date()}`);
            } else {
              setIsDraftMode(false);
              setExternalId(IMID ? IMID : item?.itemId ? item?.itemId : sfdcID ? sfdcID : item?.conversationId ? item?.conversationId : null);
            };
          } else {
            // console.log('catch new external id value', item);
            setExternalId(IMID ? IMID : item?.itemId ? item?.itemId : item?.conversationId);
          };
        });
      } catch (e) {
        setExternalId(IMID ? IMID : item?.itemId ? item?.itemId : item?.conversationId);
        console.log('sfdcId property could not be set', e);
      };
      setExchangeIdSet(externalIdFields);
    } else if (item?.itemType === 'appointment') {
      // iOS calendar has limitations on IDs TODO: find another method
      if (Office.context.platform === "iOS" || Office.context.platform === "Android") {
        logError({
          functionName: 'likely wrong id - need to fuzzy match for internalID',
          error: JSON.stringify(item),
          additionalNotes: 'The id used in iOS calendar may not be unique',
          displayedToUser: false,
          level: 'warn'
        });
        const externalIdFields = [
          { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) },
          { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId?.substring(0, 255) },
          { 'CT_PE__Exchange_Item_Id__c': item?.itemId },
          { 'CT_PE__Exchange_Thread_Id__c': item?.seriesId }
        ];
        setIsDraftMode(false);
        setExternalId(item?.itemId ? item?.itemId : Office?.context?.mailbox?.initialData);
        setExchangeIdSet(externalIdFields);
      } else {
        // office has a complicated way to get iCalUid (when not using graph api) which is moved to helperfunctions
        try {
          getExtendedIds((err, res) => {
            if (!err) {
              // res will be the iCalUid
              // let externalIdFields = [{ 'CT_PE__Exchange_iCalUID_255__c': res?.substring(0, 255) }, { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) }, { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId?.substring(0, 255) }];
              const externalIdFields = [
                { 'CT_PE__Exchange_iCalUID_255__c': res?.substring(0, 255) },
                { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) },
                { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId?.substring(0, 255) },
                { 'CT_PE__Exchange_iCalUID__c': res },
                { 'CT_PE__Exchange_Item_Id__c': item?.itemId },
                { 'CT_PE__Exchange_Thread_Id__c': item?.seriesId }
              ];
              item.exchange_iCalUID = res;
              setExternalId(res ? res : item?.itemId);
              setExchangeIdSet(externalIdFields);
            } else {
              // let externalIdFields = [{ 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) }, { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId?.substring(0, 255) }];
              const externalIdFields = [
                { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) },
                { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId?.substring(0, 255) },
                { 'CT_PE__Exchange_Item_Id__c': item?.itemId },
                { 'CT_PE__Exchange_Thread_Id__c': item?.seriesId }
              ];
              if (err === "EventOutlookUid unavailable: The id can't be retrieved until the item is saved.. Probably just a new event") {
                setSaveResultToast(<ToastMessage message={"Saved interaction may not be seen by all users, please send meeting before saving"} severity={'info'} title={"Currently in draft mode"} type={'alert'} />);
                try {
                  Office.context.mailbox.item?.loadCustomPropertiesAsync(function (asyncResult) {
                    let customProps = asyncResult.value;
                    if (customProps) {
                      const sfdcID = customProps.get('sfdcId');
                      setExternalId(sfdcID ? sfdcID : `draft-${new Date()}`);
                    } else {
                      console.log('draft mode', item);
                      setIsDraftMode(true);
                      setExternalId(`draft-${new Date()}`);
                    };
                  });
                } catch (e) {
                  setIsDraftMode(true);
                  setExternalId(`draft-${new Date()}`);
                  // console.log('sfdcId property could not be set', e);
                };
              } else if (err.message === "API not supported for shared folders." || err.name === "AccessRestricted") {
                // TODO get error message to provide - if we doo change long displayed to user
                // setSaveResultToast(<ToastMessage message={"Saved interaction may be duplicated in the future (if item id changes) as we do not have access to the iCalUID in shared folders"} severity={'info'} title={"API not supported for shared folders."} type={'alert'} />);
                setIsDraftMode(false);
                setExternalId(item?.itemId);
                logError({
                  functionName: 'getExtendedIds',
                  error: err.message,
                  additionalNotes: 'not able to get iCallUID from shared mailbox',
                  displayedToUser: false,
                  level: 'info'
                });
              } else {
                // console.log('failed icaluid', err);
                setIsDraftMode(false);
                setExternalId(item?.itemId);
              };
              setExchangeIdSet(externalIdFields);
            };
          });
        } catch (error) {
          logError({
            functionName: 'getOutlookRecordId',
            error: error,
            additionalNotes: 'getExtendedIds failure',
            displayedToUser: false,
            level: 'error'
          });
        };
      };
    };
  };

  const getPipeVersion = async () => {
    if (typeof pipeVersion === 'number') return pipeVersion;
    //SELECT Id, Name, NamespacePrefix, IsSalesforce, MajorVersion, MinorVersion FROM Publisher WHERE NamespacePrefix = 'CT_PE'
    const soqlString = `SELECT IsSalesforce,MajorVersion,MinorVersion FROM Publisher WHERE NamespacePrefix='CT_PE' AND IsSalesforce=FALSE`;
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };

    try {
      const response = await axios.get(url, optionsV3);
      const result = await response.data;
      if (result?.records) {
        // salesforceUser?.debugMode && console.log("%cSUCCESS: Get Publisher", "color: cyan; font-size: 12px;", result.records);
        const packageVersion = result?.records[0].MajorVersion;
        setPipeVersion(packageVersion);
        return packageVersion;
      };
    } catch (error) {
      logError({
        functionName: 'getPipeVersion',
        error: error,
        additionalNotes: 'setting pipe version to 14',
        displayedToUser: false,
        level: 'warn'
      });
      // todo: should there be any more error handling here?
      if (error?.response?.status !== 401) {
        console.warn("GET Pipe version error, setting version to 14", error);
        setPipeVersion(14);
      };
    };
    return 14;
  };

  const getRecordTypeId = async () => {
    const soqlString = `SELECT+Id,Name+FROM+RecordType+WHERE+sObjectType='CT_PE__Activity__c'`;
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };

    try {
      const response = await axios.get(url, optionsV3);
      const result = await response.data;
      if (result?.records) {
        // salesforceUser?.debugMode && console.log("%cSUCCESS: Get RecordTypeId", "color: cyan; font-size: 12px;", result.records);
        let returnRecordType;
        result.records.forEach(record => {
          if (record.Name === 'Email') {
            setRecordTypeIdEmail(record.Id);
            if (logType === 'email') {
              returnRecordType = record.Id;
            }
          } else if (record.Name === 'Meeting') {
            setRecordTypeIdMeeting(record.Id);
            if (logType === 'meeting') {
              returnRecordType = record.Id;
            }
          };
        });
        return returnRecordType;
      };
    } catch (error) {
      logError({
        functionName: 'getRecordTypeId',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      // todo: should there be any error handling here?
      console.warn("Get Interaction RecordTypes", error);
    }
    return null;
  };

  /**
   * Google login procedure.
   * @function
   * @memberof Context/context
   * @description Initiates the Google authentication process, opens a consent screen, and handles the post-login setup.
  */
  const googleLogin = async () => {
    chrome.identity.getAuthToken({ interactive: true }, function (token) {
      if (chrome.runtime.lastError) {
        // console.warn('google login error', chrome.runtime.lastError.message);
        return;
      } else {
        chrome.identity.getProfileUserInfo({ accountStatus: 'ANY' }, function (user_info) {
          salesforceUser?.debugMode && console.log('%cLogging in user as:', "color: cyan; font-size: 12px;", user_info);
          setGoogleAuthUser(user_info);
          setGoogleAuthToken(token);
        });
      };
    });
  };

  /**
   * Google logout procedure.
   * @function
   * @memberof Context/context
   * @description Logs out the Google user and clears related data from storage.
  */
  const googleLogout = () => {
    // chrome.identity.removeCachedAuthToken({ token: googleAuthToken }, function () {
    chrome.identity.clearAllCachedAuthTokens(function () {
      // Make a request to revoke token
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `https://accounts.google.com/o/oauth2/revoke?token=${googleAuthToken}`);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          chrome.tabs.onUpdated.removeListener(chromeAddTabChangeListenerWithToken);
          setGoogleAuthToken(null);
          setGoogleAuthUser(null);
          setIsLoginScreenOpen(true);
        }
      };
      xhr.send();
    });
  };

  const handleNewDataArray = (items) => {
    setSaveResultToast(null);
    const newStorage = {};
    items.forEach(item => {
      if (!newStorage[item.sectionId]) {
        newStorage[item.sectionId] = {};
      }
      let newValue = item.value;
      if (newValue) {
        if (item.type === "richtext") {
          newValue = decodeHTMLString(newValue);
        }
        if (item.autoTruncate) {
          newValue = getTruncatedText(item.value, item);
        }
      }
      newStorage[item.sectionId][item.inputId] = {
        ...item,
        value: newValue,
        type: item.type,
        targetField: item.targetField
      };
    });

    //console.log('newStorage', newStorage);
    setValueStorage(newStorage);
  };

  /**
   * Handles changes in input fields with detail about the input object.
   * @function
   * @param {object} detail - Details of the input change event.
   * @memberof Context/context
   * @description Processes changes in input fields, updates related states, and manages dependencies between fields.
  */
  const handleOnChangeWithDetail = (detail) => {
    if (detail.targetObject?.indexOf('Activity_Link__c') >= 0) {
      // Create a set of existing linked item Id
      const existingChildIdSet = new Set();
      if (existingLinks) {
        Object.values(existingLinks).forEach(itemList => {
          itemList.forEach(item => {
            existingChildIdSet.add(item.childId);
          });
        });
      }

      // console.log("handleOnChangeWithDetail", detail);
      // Create storage for interaction links
      setLinkStorage(prev => {
        const newStorage = { ...prev };
        let tempSet = new Set();
        if (!newStorage[detail.targetField]) {
          newStorage[detail.targetField] = [];
        } else {
          tempSet = new Set(newStorage[detail.targetField]);
          if (detail.unselected?.length > 0) {
            detail.unselected.forEach(item => {
              tempSet.delete(item.value);
            });
          }
        }
        detail.value.forEach(item => {
          tempSet.add(item.value);
        });
        if (tempSet.size === 0) {
          delete newStorage[detail.targetField];
        } else {
          newStorage[detail.targetField] = [...tempSet.values()];
        }
        // console.log("new link storage", newStorage);
        return newStorage;
      });

      // Track removed tagged items in interaction links
      if (detail.unselected?.length > 0) {
        const deleteStorage = { ...deleteLinkStorage };
        if (!deleteStorage[detail.targetField]) {
          deleteStorage[detail.targetField] = [];
        }
        detail.unselected.forEach(item => {
          deleteStorage[detail.targetField].push(item.value);
        });
        setDeleteLinkStorage(deleteStorage);
      };

      // Check auto populate property to auto populate related inputs (Interaction Links)
      const tempAutoPop = {};
      detail.selected?.forEach(item => {
        // Skip auto populating if a link already exist
        if (item.autoPop && !existingChildIdSet.has(item.value)) {
          for (let targetInput in item.autoPop) {
            if (!!item.autoPop[targetInput]) {
              if (!tempAutoPop[targetInput]) {
                tempAutoPop[targetInput] = [];
              }
              if (tempAutoPop[targetInput].indexOf(item.autoPop[targetInput]) < 0) {
                tempAutoPop[targetInput].push(item.autoPop[targetInput]);
              }
            }
          }
        }
      });
      //console.log("--------------- auto pop storage", tempAutoPop, Object.keys(tempAutoPop).length);
      setAutoPopStorage(tempAutoPop);
    } else {
      // Special handling for Currency Code Fields.
      // Potentially extend this to handle other field dependencies like picklist
      if (screenType === 'interaction') {
        const newSections = processDependentInputs(detail, sections);
        if (newSections) {
          setSections(newSections);
        }
      } else {
        const newSections = processDependentInputs(detail, caseConfig.sections);
        if (newSections) {
          const newConfig = { ...caseConfig };
          newConfig.sections = newSections;
          setCaseConfig(newConfig);
        }
      }

      // Set new value
      setValueStorage(prev => {
        const newStorage = { ...prev };
        if (!newStorage[detail.sectionId]) {
          newStorage[detail.sectionId] = {};
        }
        newStorage[detail.sectionId][detail.inputId] = {
          value: detail.value,
          type: detail.type,
          targetField: detail.targetField
        };
        // console.log('value storage', newStorage);
        return newStorage;
      });
    };
  };

  /**
   * Initializes component configurations and setups after authentication.
   * @function
   * @async
   * @memberof Context/context
   * @description Fetches configuration data and initializes settings after user is authenticated with Salesforce.
  */
  const initializeConfigurations = async () => {
    setIsLoading(true);
    const interactionRecordTypeId = await getRecordTypeId();
    const fePkgVersion = await getPipeVersion();
    await getConfigData(interactionRecordTypeId, fePkgVersion);
    setIsLoading(false);
  };

  const loadNewItem = async (eventArgs) => {
    setPayload(null);
    resetStorageData();
    setDetectedEntities(null);
    setIsLoading(true);
    // event args are only present on first load with inital results from event fire
    let item = await Office?.context?.mailbox?.item;
    const _item = JSON.parse(JSON.stringify(item));

    if (item && (item !== null)) {
      salesforceUser?.debugMode && console.log('%cNew item', "color: cyan; font-size: 12px;", item);
      setScreenType('intro');
      if (item?.itemId) { setCurrentTabId(item?.itemId) };
      if (item?.itemType === 'message') {
        if (item.getComposeTypeAsync) {
          _item.dateTimeCreated = item.dateTimeCreated;
          _item.itemId = item.itemId ? getItemRestId(item.itemId) : item.itemId;
          _item.itemType = item.itemType;
          await getOutlookRecordId(_item);
          const promises = await loadPromiseMailASynch(item, _item);
          Promise.all(promises).then(async (values) => {
            //console.log('async mail promise', values)
            setPayload(_item);
            setIsLoading(false);
            setIsLoadingGlobal(false);
          }).catch((error) => {
            setPayload(_item);
            setIsLoadingGlobal(false);
            setIsLoading(false);
            console.error('mail item promise error', error);
          });
        } else {
          item.exchange_Item_Id = item.itemId ? getItemRestId(item.itemId) : item.itemId;
          item.exchange_Thread_Id = item.conversationId;
          item.exchange_Internet_Message_Id = item.internetMessageId?.replace('<', '')?.replace('>', '');
          item.exchange_cc = item.cc;
          item.exchange_bcc = item.bcc;
          item.exchange_dateTimeCreated = item.dateTimeCreated;
          item.exchange_from = item.from;
          item.exchange_itemType = item.itemType;
          item.exchange_sender = item.sender;
          item.exchange_subject = item.subject;
          item.exchange_to = item.to;
          Office.context.mailbox.item.body.getAsync(
            "html",
            async function (result) {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                const body = restructureHTML(result.value);
                item.emailbody = body;
                Office.context.mailbox.item.body.getAsync(
                  "text",
                  async function (result) {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                      item.textemailbody = result.value;
                    };
                    await getOutlookRecordId(item);
                    setPayload(item);
                  }
                )
              } else {
                await getOutlookRecordId(item);
                setPayload(item);
              };
              setIsLoading(false);
              setIsLoadingGlobal(false);
            }
          );
        };

      } else if (item?.itemType === 'appointment') {
        // console.log('in context load new calendar item', item)
        setLogType('meeting');
        _item.seriesId = item.seriesId;
        _item.itemId = item.itemId ? getItemRestId(item.itemId) : null;
        _item.itemClass = item.itemClass;
        _item.itemType = item.itemType;
        _item.subject = item.subject;
        _item.isAllDayEvent = undefined;
        _item.start = item.start;
        _item.end = item.end;
        _item.recurrence = item.recurrence;
        _item.location = item.location;
        _item.organizer = item.organizer;
        _item.requiredAttendees = item.requiredAttendees;
        _item.attachments = item.attachments;



        // if (Office?.context?.platform === "iOS" || Office?.context?.platform === "Android") {
        //   if (salesforceUser?.graphEnabled) {
        //     const graphToken = await getGraphToken();
        //     if (graphToken) {
        //       let headers = {
        //         headers: {
        //           'Authorization': 'Bearer ' + graphToken,
        //           'Content-Type': 'application/json'
        //         }
        //       };
        //       try {
        //         // const request = await axios.get(`${constants.REACT_SERVER_URL}/api/v2/graph/user`, headers);
        //         // const request = await axios.get(`${constants.REACT_SERVER_URL}/api/v2/graph/debug`, headers);
        //         const request = await axios.get(`${constants.REACT_SERVER_URL}/api/v2/graph/calendar/${_item.itemId}`, headers);

        //         console.log('request', request?.data);
        //         logError({
        //           functionName: 'loadNewItem',
        //           error: JSON.stringify(request?.data),
        //           additionalNotes: 'graph info for mobile calendar',
        //           displayedToUser: false,
        //           level: 'info'
        //         });
        //       } catch (error) {
        //         console.log('error here', error);
        //       };
        //     } else {
        //       logError({
        //           functionName: 'loadNewItem',
        //           error: 'failed to fetch graph',
        //           additionalNotes: JSON.stringify(error),
        //           displayedToUser: false,
        //           level: 'info'
        //         });
        //       console.log('failed to fetch graphToken');
        //     };
        //   };
        // };



        if (!_item.itemId) {
          // TODO: need to find solid reason when user is in draft mode
          // setIsDraftMode(true);
          // setSaveResultToast(<ToastMessage message={"Saving an interaction before sending invite could lead to duplicate issues in Salesforce."} severity={'info'} title={"Currently in draft mode"} type={'alert'} />);
          logError({
            functionName: 'loadNewItem',
            error: 'no item id found',
            additionalNotes: 'Office.context.mailbox.item.itemId is empty',
            displayedToUser: false,
            level: 'info'
          });
          await getEventOutlookUid('empty', _item);
        };
        getOutlookRecordId(_item);
        const promises = await loadPromiseMeetingsASynch(item, _item);
        Promise.all(promises).then(async (values) => {
          setPayload(_item);
          setIsLoading(false);
          setIsLoadingGlobal(false);
          // if (Office.context.platform === "iOS" || Office.context.platform === "Android") {
          //   // TODO is this needed to get id for mobile?
          //   getOutlookRecordId(_item);
          // };
        }).catch((error) => {
          logError({
            functionName: 'promises complete catch error',
            error: JSON.stringify(error),
            additionalNotes: 'load new item fail',
            displayedToUser: false,
            level: 'info'
          });
          setPayload(_item);
          setIsLoadingGlobal(false);
          setIsLoading(false);
          console.error('meeting item promise error', error);
        });
      } else {
        // console.error('issue fetching item');
        setIsLoadingGlobal(false);
        setIsLoading(false);
        if (Office?.context?.platform === "iOS" || Office?.context?.platform === "Android") {
          logError({
            functionName: 'trying to set fake item id',
            error: JSON.stringify(Office.context.mailbox.item),
            additionalNotes: 'Office.context.mailbox.item',
            displayedToUser: false,
            level: 'info'
          });
          setExternalId(Office?.context?.mailbox?.initialData?.id);
        };
      };
    } else {
      // console.error('item is null');
      logError({
        functionName: 'null',
        error: JSON.stringify(Office.context.mailbox.item),
        additionalNotes: 'Office.context.mailbox.item',
        displayedToUser: false,
        level: 'info'
      });
      setIsLoadingGlobal(false);
      setIsLoading(false);
    };
  };

  const logEmailValues = async (id) => {
    let token = googleAuthToken;
    let headers = {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    };
    const url = `${constants.REACT_GMAIL_ENDPOINT}/${id}`;
    try {
      const response = await axios.get(url, headers);

      setGoogleMessageId(response?.data?.id);
      buildGoogleEmailData(response.data);
      // console.log('google response', response.data)
    } catch (error) {
      logError({
        functionName: 'logEmailValues',
        error: error,
        additionalNotes: 'if 401 we will try to get new token',
        displayedToUser: false,
        level: 'error'
      });
      // console.warn('error fetching email from google', error);
      if (error?.response?.status === 401) {
        const newToken = await checkForGoogleAuth();
        if (newToken) {
          try {
            token = newToken;
            const newResponse = await axios.get(url, headers);
            buildGoogleEmailData(newResponse?.data);
          } catch (err) {
            // console.warn('failed to refresh google auth token', err);
            setIsLoginScreenOpen(true);
            setSaveResultToast(
              <ToastMessage message={'unable to authenticate please refresh page or sign in again'} severity={'warning'} title={'failed to verify credentials'} type={'alert'} />
            );
          }
        };
      } else if (error.response?.status === 404) {
        console.warn('response from gmail is 404 - no email with that id exists for this google account', error);
        setIsLoginScreenOpen(true);
        setSaveResultToast(
          <ToastMessage message={'You are currently logged in with another Gmail account. Please use the "Google Workspace Logout" button to log back in using this one.'} severity={'warning'} title={'Failed to verify credentials'} type={'alert'} />
        );
      }
    };
  };

  /**
   * Logs an error into the state for debugging purposes when Salesforce's debug mode is active.
   * @function
   * @param {Object} params - The parameters for the error to log.
   * @param {Error|string} params.error - The error object or message to log.
   * @param {string} params.functionName - The name of the function where the error occurred.
   * @param {string} [params.additionalNotes=''] - Optional additional notes related to the error.
   * @param {boolean} [params.displayedToUser=false] - Indicates whether the error message was shown to the user.
   * @param {string} [params.level='error'] - The severity level of the error.
   * @memberof Context/context
   * @description This function logs an error into the state, which can be useful for debugging issues in development
   * or production environments. It only logs errors when Salesforce's debug mode is enabled, ensuring that
   * error logging does not occur in normal operations. The function stores errors with a timestamp, the function name,
   * error message, any additional notes, and the error's display status to the user.
  */
  const logError = ({ error, functionName, additionalNotes = '', displayedToUser = false, level = 'error' }) => {
    if (!salesforceUser?.debugMode) { return; };
    const errorLog = {
      timestamp: new Date(),
      functionName,
      level,
      errorMessage: error?.message || error ? JSON.stringify(error) : '',
      additionalNotes,
      displayedToUser
    };
    setErrorLogs(prevLogs => [...prevLogs, errorLog]);
  };

  const onLogCase = () => {
    let newData = [];
    if (logType === "email") {
      newData = getNewDataFromSourceMap_Email(caseSourceMap);
    } else if (logType === "meeting") {
      newData = getNewDataFromSourceMap_Meeting(caseSourceMap);
    };

    buildExistingCaseData(newData);
    // console.log("onLogCase newData", logType, newData);
    handleNewDataArray(newData);
    setScreenType("case");
  };

  /**
   * Logs the data related to email interactions into Salesforce.
   * @function
   * @memberof Context/context
   * @description Prepares and logs email interaction data into Salesforce based on current state and configuration.
  */
  const onLogData = async (detail) => {
    const selectedItems = detail?.relatedItems;
    const newSections = [...sections];
    newSections.forEach(sectionConfig => {
      sectionConfig.inputFields?.forEach(inputConfig => {
        if (inputConfig.targetObject.indexOf('Activity_Link__c') >= 0) {
          let defaultSet = new Set();
          if (selectedItems && selectedItems[inputConfig.id]) {
            defaultSet = new Set(selectedItems[inputConfig.id]);
          }
          if (existingLinks && existingLinks[inputConfig.id]) {
            existingLinks[inputConfig.id].forEach(item => {
              defaultSet.add(item.childId);
            });
          }
          if (defaultSet.size > 0) {
            inputConfig.defaultValue = [...defaultSet.values()];
          } else {
            inputConfig.defaultValue = null;
          }
        }
      });
    });

    setSections(newSections);

    let newData = [];
    if (logType === "email") {
      newData = getNewDataFromSourceMap_Email(sourceFieldMap);
    } else if (logType === "meeting") {
      newData = getNewDataFromSourceMap_Meeting(sourceFieldMap);
    };

    buildExistingInteractionData(newData);
    // console.log('buildgoogledata newData', newData);
    handleNewDataArray(newData);
    setScreenType("interaction");
    // buildMessageToSendToContentScript();
  };

  const processMessage = (message) => {
    // console.log('processing...', message);
    if (!message) return;
    let parsedMessage;
    if (message?.type === "dialogMessageReceived") {
      if (trustedDomains.includes(message.origin)) {
        // console.log('signin dialogMessageReceived...', message);
        try {
          parsedMessage = JSON.parse(message.message);
        } catch (error) {
          parsedMessage = JSON.parse(decodeURIComponent(message.message));
        };
        setUserCookieData(JSON.stringify(parsedMessage));
        localStorage.setItem('_investorflow', JSON.stringify(parsedMessage));
        if (isOfficeSetSupported('1.8')) {
          parsedMessage?.category && createSalesforceCategory(parsedMessage?.category, parsedMessage?.categoryColor ? parsedMessage?.categoryColor : null);
        };
        dialog?.close();
      };
    } else if (typeof message === 'string') {
      // console.log('else - string --- ', message);
      try {
        parsedMessage = JSON.parse(message);
      } catch (error) {
        parsedMessage = JSON.parse(decodeURIComponent(message));
      };
      if (!parsedMessage['_data']?.Error) {
        const parsedCookieData = parsedMessage?.dialogMessage?.messageContent ? JSON.parse(parsedMessage?.dialogMessage?.messageContent) : parsedMessage;
        setUserCookieData(JSON.stringify(parsedCookieData));
        localStorage.setItem('_investorflow', JSON.stringify(parsedCookieData));
        if (isOfficeSetSupported('1.8')) {
          parsedCookieData?.category && createSalesforceCategory(parsedCookieData?.category, parsedCookieData?.categoryColor ? parsedCookieData?.categoryColor : null);
        };
      } else {
        console.log('failed message from popup - if you see this message find a dev');
      };
    } else {
      console.log('need more error handling in processing message in salesforce login in outlook - if you see this find a dev', message);
    };
    window.removeEventListener('message', checkIncomingMessage);
    if (dialog) dialog.close();
  };

  /**
   * Refreshes Salesforce access token when it expires.
   * @function
   * @async
   * @memberof Context/context
   * @description Attempts to refresh the Salesforce access token using the stored refresh token.
  */
  const refreshSalesforceToken = async () => {
    salesforceUser?.debugMode && console.log('%cRefreshing salesforce access token...', "color: cyan; font-size: 12px;");
    // check for salesforce login
    let investorflowCookie = null;
    if (constants.EMAIL_CLIENT === 'outlook') {
      investorflowCookie = localStorage.getItem('_investorflow');
      investorflowCookie = JSON.parse(investorflowCookie);
    } else {
      const data = await chrome.storage.session.get(["investorflow"]);
      investorflowCookie = data?.investorflow;
    };
    if (investorflowCookie) {
      // console.log('investorflowCookie', investorflowCookie);
      const refreshURL = `${constants.REACT_SERVER_URL}/api/v3/auth/refresh/${investorflowCookie?.account}/${investorflowCookie?.instance}`;
      try {
        const options = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': investorflowCookie?.token, 'x-refresh': investorflowCookie?.refresh, 'x-iv': investorflowCookie?.iv } };
        const response = await axios.get(refreshURL, options);
        // console.log('successfuly refreshed salesforce token', response?.data);
        logError({
          functionName: 'refreshSalesforceToken',
          error: 'success',
          additionalNotes: 'salesforce refresh successful',
          displayedToUser: false,
          level: 'info'
        });
        if (response.data?.iv) {
          investorflowCookie.token = response.data?.token;
          investorflowCookie.refresh = response.data?.refresh ? response.data?.refresh : investorflowCookie.refresh;
          investorflowCookie.iv = response.data?.iv;
        };
        if (constants.EMAIL_CLIENT === 'outlook') {
          localStorage.setItem('_investorflow', JSON.stringify(investorflowCookie));
        } else {
          await chrome.storage.session.set({ investorflow: investorflowCookie });
        }
        setSalesforceUser(investorflowCookie);
        return { token: response.data };
      } catch (error) {
        console.warn('request for salesforce refresh token failed', error);
        logError({
          error: error,
          functionName: 'refreshSalesforceToken',
          additionalNotes: 'request for salesforce refresh token failed',
          displayedToUser: false,
          level: 'error'
        });
        setSaveResultToast(<ToastMessage message={"Could not refresh your session.  Please login again."} severity={'error'} title={"We ran into a problem."} type={'alert'} />);
        setScreenType("intro");
        setIsLoginScreenOpen(true);
        return { error: 'error' };
      };
    } else {
      console.warn('no salesforce refresh token found, cannot refresh');
      logError({
        error: 'could not refresh',
        functionName: 'refreshSalesforceToken',
        additionalNotes: 'no salesforce refresh token found, cannot refresh',
        displayedToUser: false,
        level: 'error'
      });
      setSaveResultToast(<ToastMessage message={"Could not refresh your session.  Please login again."} severity={'error'} title={"We ran into a problem."} type={'alert'} />);
      setScreenType("intro");
      setIsLoginScreenOpen(true);
      return { error: 'error' };
    };
  };

  const resetAllState = () => {
    setSaveResultToast(null);
    setPayload(null);
    setSections([]);
    setUserCookieData(null);
    setIsConnected(false);
    setSalesforceUser(null);
    setCaseId(null);
    setCaseConfig(null);
    setExternalId(null);
    setRelatedListConfigs([]);
  };

  const resetStorageData = () => {
    setCaseId(null);
    setExistingInteraction(null);
    setExternalId(null);
    setExistingLinks(null);
    setLinkStorage({});
    setInteractionId(null);
  };

  /**
   * Retrieves and logs existing case data from Salesforce.
   * @function
   * @async
   * @memberof Context/context
   * @description Fetches existing case data related to the current interaction from Salesforce and updates the state accordingly.
  */
  const retrieveExistingCase = async () => {
    const fieldNames = new Set(['Id', caseConfig.externalIdField]);
    caseConfig.sections.forEach(sectionConfig => {
      sectionConfig.inputFields.forEach(inputConfig => {
        fieldNames.add(inputConfig.targetField);
      });
    });

    if (pipeVersion > 15) {
      exchangeIdSet.forEach((item) => {
        fieldNames.add(Object.keys(item)?.[0])
      });
    };
    const uniqueFieldNames = [...fieldNames];
    const soqlString = pipeVersion > 15
      ? `SELECT ${[...uniqueFieldNames.values()].join(',')} FROM ${caseConfig.targetObject} WHERE ${createExternalIdSQLQuery(externalId.substring(0, 255), salesforceUser, logType, true)}`
      : `SELECT ${[...uniqueFieldNames.values()].join(',')} FROM ${caseConfig.targetObject} WHERE ${caseConfig.externalIdField}='${externalId}'`;
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    try {
      const response = await axios.post(url, { data: JSON.stringify(soqlString) }, optionsV3);
      const result = await response.data;
      if (result?.records?.length > 0) {
        // console.log('result?.records', result?.records);
        if (result?.records?.length === 1) {
          setCaseId(result.records[0].Id);
          existingCase = result.records[0];
        } else {
          // TODO filter through full id list not just 255 once case object has multiple fields
          setCaseId(result.records[0].Id);
          existingCase = result.records[0];
        };
      } else {
        setCaseId(null);
        existingCase = null;
      }
    } catch (error) {
      logError({
        functionName: 'retrieveExistingCase',
        error: error,
        additionalNotes: '',
        displayedToUser: true,
        level: 'error'
      });
      console.log("unable to reach salesforce server to fetch exisiting case", error);
      if (error?.response?.data?.name === "INVALID_FIELD" || error?.response?.data?.errorCode === "INVALID_FIELD") {
        setSaveResultToast(
          <ToastMessage
            message={
              <div>
                <span>Unable to retrieve {caseConfig.buttonLabel} record. Please reach out to an admin to confirm the following:</span>
                <ul style={{ paddingInlineStart: "20px", margin: 0 }}>
                  <li>All API names are spelled correctly.</li>
                  <li>All fields used in {caseConfig.buttonLabel} screen must exist in the org.</li>
                  <li>Current user has Edit access to all fields in {caseConfig.buttonLabel} screen.</li>
                </ul>
              </div>
            }
            severity={'error'}
            title={"Invalid Field Detected"}
            type={'alert'}
          />
        );
        // } else if (error?.status === 401 || error?.response?.status === 401) {
        //   refreshSalesforceToken();
      } else if (error?.response?.status === 400) {
        setScreenType("intro");
        setIsLoginScreenOpen(true);
        setSaveResultToast(
          <ToastMessage
            message={`Addin failed to retrieve and process logged data for ${caseConfig.targetObject} with ${caseConfig.externalIdField} containing ${externalId}.`}
            severity={'error'}
            title={"Salesforce Error"}
            type={'alert'} />
        );
      };
    };
  };

  /**
   * Fetches and logs existing interaction data from Salesforce.
   * @function
   * @async
   * @memberof Context/context
   * @description Fetches existing interaction data from Salesforce based on the current external ID and updates the state.
  */
  const retrieveExistingInteraction = async () => {
    setExistingLinks(null);
    const fieldNames = ['Id'];
    sections.forEach(sectionConfig => {
      sectionConfig.inputFields.forEach(inputConfig => {
        if (inputConfig.targetObject.indexOf('Activity__c') >= 0) {
          if (fieldNames.indexOf(inputConfig.targetField) < 0) {
            fieldNames.push(inputConfig.targetField);
          };
        };
      })
    });
    if (exchangeIdSet && exchangeIdSet.length > 0) {
      exchangeIdSet.forEach((item) => {
        fieldNames.push(Object.keys(item)?.[0]);
      });
    };
    const uniqueFieldNames = [...new Set(fieldNames)];
    const soqlString = `SELECT ${uniqueFieldNames.join(',')} FROM CT_PE__Activity__c WHERE ${createExternalIdSQLQuery(externalId.substring(0, 255), salesforceUser, logType)}`;
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}`;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    try {
      const response = await axios.post(url, { data: JSON.stringify(soqlString) }, optionsV3);
      const result = await response.data;
      if (result?.records?.length > 0) {
        // console.log('result.records', result.records)
        if (result?.records?.length === 1) {
          setInteractionId(result.records[0].Id);
          setExistingInteraction(result.records[0]);
          retrieveExistingLinks(result.records[0].Id);
        } else {
          // TODO test this with a record longer than 255 characters or where id matches more than one field
          const filtered = result.records.filter((record) => record['CT_PE__Exchange_Internet_Message_Id__c'] === externalId || record['CT_PE__Exchange_Item_Id__c'] === externalId || record['CT_PE__Exchange_Thread_Id__c'] === externalId || record['CT_PE__Exchange_iCalUID__c'] === externalId);
          if (filtered.length > 0) {
            setInteractionId(filtered[0].Id);
            setExistingInteraction(filtered[0]);
            retrieveExistingLinks(filtered[0].Id);
          } else {
            setInteractionId(null);
            setExistingInteraction(null);
          };
        };
        // } else if (constants.EMAIL_CLIENT === 'outlook' && (salesforceUser.fuzzyMatch)) {
      } else if (constants.EMAIL_CLIENT === 'outlook' && (salesforceUser.fuzzyMatch || (logType === 'meeting' && !salesforceUser.disableFuzzyMatchMobile))) {
        const succesfulMatch = await runFuzzyMatch(url, soqlString, optionsV3, logType);
        if (succesfulMatch) {
          setInteractionId(succesfulMatch.records[0].Id);
          setExistingInteraction(succesfulMatch.records[0]);
          retrieveExistingLinks(succesfulMatch.records[0].Id);
          logError({
            functionName: 'retrieveExistingInteraction',
            error: succesfulMatch.records[0].Id,
            additionalNotes: 'This Record was found via fuzzy match',
            displayedToUser: false,
            level: 'info'
          });
        } else {
          setInteractionId(null);
          setExistingInteraction(null);
        };
      } else {
        setInteractionId(null);
        setExistingInteraction(null);
      };
    } catch (error) {
      logError({
        functionName: 'retrieveExistingInteraction',
        error: error,
        additionalNotes: 'Likely need a refresh token',
        displayedToUser: true,
        level: 'error'
      });
      console.log("unable to reach salesforce server to fetch existing interaction", error);
      if (error?.status === 401 || error?.response?.status === 401) {
        return refreshSalesforceToken();
      } else if (error?.response?.status === 400) {
        setScreenType("intro");
        setIsLoginScreenOpen(true);
        setSaveResultToast(
          <ToastMessage
            message={`Addin failed to retrieve and process the Interaction with ${externalIdField} containing ${externalId}. Please check Interaction data from Salesforce.`}
            severity={'error'}
            title={"Salesforce Error"}
            type={'alert'} />
        );
      } else if (error?.response?.status === 418) {
        setScreenType("intro");
        if (error.response.data?.name === "INVALID_FIELD" || error.response.data?.errorCode === "INVALID_FIELD") {
          setSaveResultToast(
            <ToastMessage
              message={
                <div>
                  <span>Unable to retrieve Interaction record. Please reach out to an admin to confirm the following:</span>
                  <ul style={{ paddingInlineStart: "20px", margin: 0 }}>
                    <li>All API names are spelled correctly.</li>
                    <li>All fields used in Log {logType === 'email' ? 'Email' : 'Meeting'} screen must exist in the org.</li>
                    <li>Current user has Edit access to all fields in Log {logType === 'email' ? 'Email' : 'Meeting'} screen.</li>
                  </ul>
                </div>
              }
              severity={'error'}
              title={"Invalid Field Detected"}
              type={'alert'}
            />
          );
        } else {
          setSaveResultToast(
            <ToastMessage
              message={`Addin failed to retrieve and process this Interaction due to improper configuration in salesforce. Please see admin for help.`}
              severity={'error'}
              title={"Salesforce Error"}
              type={'alert'} />
          );
        }
      };
    }
  };

  const retrieveExistingLinks = async (activityId) => {
    const fieldNames = ['Id'];
    const fieldIdMap = {};
    sections.forEach(sectionConfig => {
      sectionConfig.inputFields.forEach(inputConfig => {
        if (inputConfig.targetObject.indexOf('Activity_Link__c') >= 0) {
          if (fieldNames.indexOf(inputConfig.targetField) < 0) {
            fieldNames.push(inputConfig.targetField);
            fieldIdMap[inputConfig.targetField] = inputConfig.id;
          }
        }
      })
    });

    let soqlString = `SELECT+${fieldNames.join(',')}`
      + `+FROM+CT_PE__Activity_Link__c`
      + `+WHERE+CT_PE__Activity__c='${activityId}'`;
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    try {
      const response = await axios.get(url, optionsV3);
      const result = await response.data;
      if (result?.records?.length > 0) {
        const queriedLinks = {};
        await result.records.forEach(record => {
          let fieldName;
          let fieldValue;
          for (let fn in fieldIdMap) {
            if (record[fn]) {
              fieldName = fn;
              fieldValue = record[fn];
            }
          }
          if (fieldName && fieldValue) {
            if (!queriedLinks[fieldIdMap[fieldName]]) {
              queriedLinks[fieldIdMap[fieldName]] = [];
            }
            queriedLinks[fieldIdMap[fieldName]].push({
              recordId: record.Id,
              childId: fieldValue
            });
          }
        });
        // console.log('queriedLinks', queriedLinks)
        setExistingLinks(queriedLinks);
      }
    } catch (error) {
      logError({
        functionName: 'retrieveExistingLinks',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.log("unable to reach salesforce server to fetch existing links", error);
      setScreenType("intro");
      setIsLoginScreenOpen(true);
      if (error?.response?.data?.errorCode === 'INVALID_FIELD') {
        fieldNames.splice(0, 1);
        setSaveResultToast(
          <ToastMessage
            message={
              <div>
                <p>Email Add-In failed to retrieve Interaction Links related to the following Interaction {activityId}.</p>
                <p>User does not have access to one of the following fields from the Interaction Link object:</p>
                <ul style={{ marginLeft: "-1.5rem" }}>
                  {fieldNames.map((fn, key) => (
                    <li style={{ wordBreak: 'break-word' }} key={`${key}-save-error`}>{fn}</li>
                  ))}
                </ul>
                <p>Please reach out to an admin to enable access.</p>
              </div>
            }
            severity={'error'}
            title={"Salesforce Error"}
            type={'alert'}
          />
        );
      } else {
        setSaveResultToast(
          <ToastMessage
            message={`Email Add-In failed to retrieve and process Interaction Links related to the following Interaction ${activityId}. Please check Interaction data from Salesforce.`}
            severity={'error'}
            title={"Salesforce Error"}
            type={'alert'}
          />
        );
      }
    };
  };

  /**
   * @summary Retrieves a Google Message ID from a side panel message
   * @function
   * @memberof  Context/context
   * @description This function is designed to process a message received from a side panel, specifically in a split view layout. It checks if the message originates from the current tab and has a specific layout. If these conditions are met, it logs the email values associated with the message ID. This is typically used in extensions or web applications that interact with Gmail interface.
   * @param {Object} message - The message object received from the side panel. It should contain properties like 'layout' and 'messageId'.
   * @param {Object} sender - The sender object providing context about who sent the message. This typically includes details about the tab from which the message was sent.
   * @param {Function} sendResponse - A callback function used to send a response back to the message sender. Not used in the current implementation, but available for future use.
   * @returns {void}
  */
  const retrieveGoogleMessageIdFromSidePanel = (message, sender, sendResponse) => {
    // console.log('from context', message, sender);
    if (currentTabId === sender?.tab?.id && message?.messageId) {
      // logEmailValues(message?.messageId)
      setUpdateSidepanel(message?.messageId)
    };
  };

  /**
   * Handles Salesforce user login procedure.
   * @function
   * @param {string} loginID - The Salesforce login ID.
   * @param {string} type - The type of Salesforce instance.
   * @memberof Context/context
   * @description Initiates Salesforce login process, opens a login dialog or window, and handles the post-login setup.
  */
  const salesforceLoginUser = async (loginID, type, internal) => {
    if (!loginID || !type) {
      loginID = salesforceInstance?.account;
      type = salesforceInstance?.Salesforce;
    };
    const loginUrl = `${constants.REACT_SERVER_URL}/api/v3/auth/login/${type}/${loginID}`;
    const redirectUrl = `${location.origin}/login-success?redirect=${loginUrl}`;
    if (isOfficeInitialized) {
      try {
        // either log in via server or client w/redirects depending on account settings in blob storage
        const url = internal ? redirectUrl : loginUrl;
        // console.log('outlook logging in...', url);
        window.addEventListener("message", checkIncomingMessage, false);
        Office.context.ui.displayDialogAsync(url, { height: 50, width: 40 },
          function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.log('dailog failed', asyncResult.error.code = ": " + asyncResult.error.message);
            } else {
              dialog = asyncResult.value;
              // console.log('using office dialog...');
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, checkIncomingMessage);
            };
          }
        );
      } catch (error) {
        logError({
          functionName: 'salesforceLoginUser',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        console.warn('error fetching account data from server', error);
      };
    } else {
      try {
        await chrome.windows.create(
          {
            url: loginUrl,
            focused: true,
            type: "popup",
            height: 575,
            width: 425
          },
          (info) => {
            // console.log('chrome created window', info);
            addCookieListener(info.id);
          }
        );
      } catch (error) {
        logError({
          functionName: 'salesforceLoginUser',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        console.warn('error fetching account data from server', error);
      };
    };
  };

  /**
   * Handles Salesforce user logout procedure.
   * @function
   * @memberof Context/context
   * @description Logs out the Salesforce user and clears related data from storage.
  */
  const salesforceLogoutUser = async () => {
    if (constants.EMAIL_CLIENT === 'outlook') {
      try {
        const options = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };
        const url = `${constants.REACT_SERVER_URL}/api/v3/auth/logout`;
        // await axios.post(url, salesforceUser, options);
        // await axios.post(url, { salesforceUser }, { credentials: 'same-origin' });
      } catch (error) {
        logError({
          functionName: 'salesforceLogoutUser',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        console.warn('error logging out of server out', error);
      };

      localStorage.removeItem("_investorflow");
      Cookies.remove('_investorflow', { domain: 'outlook.office365.com', path: '/' });

      resetAllState();
    } else {
      chrome.storage.session.remove(["investorflow"]).then(() => {
        setSalesforceUser(null);
        chrome.storage.local.remove(["investorflow"]);
      });
    };
    setThemeColors({ primary: "#007bc2", secondary: "", tertiary: "" });
  };

  /** ENTITY RECOGNITION */
  const runEntityDetectionService = () => {
    //window.sessionStorage.setItem(constants.ENTITY_RECOGNITION_STORAGE, "[]");
    const storageJSON = window.sessionStorage.getItem(constants.ENTITY_RECOGNITION_STORAGE);

    let isNewDetection = true;
    if (!!storageJSON) {
      JSON.parse(storageJSON).some(entityObj => {
        if (entityObj.externalId === externalId) {
          setDetectedEntities(entityObj);
          isNewDetection = false;
          return true;
        }
      });
    };

    if (isNewDetection && payload) {
      const detectObjects = {};
      relatedListConfigs?.forEach(rl => {
        if (!!rl.entityRecognitionObject) {
          detectObjects[rl.entityRecognitionObject] = rl.objectName;
        };
      });

      if (Object.keys(detectObjects).length > 0) {
        if (logType === "email") {
          const emailBody = isOfficeInitialized ? payload?.textemailbody : Base64.decode(getBase64FromBase64Url(payload?.parts?.[0]?.body?.data ? payload?.parts?.[0]?.body?.data : payload?.parts?.[0]?.parts?.[0]?.body?.data));
          getEntitiesFromPayload(limitCharacters(emailBody?.trim()), detectObjects);
        } else if (logType === "meeting") {
          const meetingBody = isOfficeInitialized ? payload.textcalendarbody : parseGoogleItemData_Meeting(payload.description, "text");
          if (meetingBody) {
            getEntitiesFromPayload(limitCharacters(meetingBody?.trim()), detectObjects);
          };
        };
      };
    };
  };

  const getEntitiesFromPayload = async (payloadBody, detectObjects) => {
    if (!payloadBody) return;
    const parsedPayload = payloadBody.replace(/"|<|>/g, '');
    if (!parsedPayload || !detectObjects) return;
    // console.log('parsedPayload', parsedPayload);

    setDetectEntityIsRunning(true);
    const url = `${constants.REACT_SERVER_URL}/api/v2/misc/entity/recognition`;

    try {
      const response = await axios.post(url, { "data": parsedPayload, "entity_types": detectObjects, "salesforceUser": salesforceUser, "externalId": externalId });
      if (!response?.data?.error) {
        salesforceUser?.debugMode && console.log("%cEntity Recognition raw data", "color: cyan; font-size: 12px;", JSON.stringify(response?.data, null, " "));
        updateDetectedEntityStorage(response.data);
        setDetectedEntities(response.data);
      } else {
        if (response?.data?.error === 401) {
          return refreshSalesforceToken();
        } else {
          console.log('SF error fetching relationship data', response);
        };
      };
    } catch (error) {
      logError({
        functionName: 'getEntitiesFromPayload',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.log('catch error fetching relationship data', error);
    };

    setDetectEntityIsRunning(false);
  };

  const updateDetectedEntityStorage = (entityMap) => {
    const storageJSON = window.sessionStorage.getItem(constants.ENTITY_RECOGNITION_STORAGE);
    let storage = !!storageJSON ? JSON.parse(storageJSON) : [];
    const cacheLimit = constants.ENTITY_RECOGNITION_CACHE_LIMIT;
    if (storage.length >= cacheLimit) {
      storage = storage.slice(1, storage.length);
    }
    storage.push(entityMap);
    //console.log("storage added", storage);
    window.sessionStorage.setItem(constants.ENTITY_RECOGNITION_STORAGE, JSON.stringify(storage));
  };
  /** END ENTITY RECOGNITION */

  // attributes and their respective setters are placed on the same line
  return (
    <PageContext.Provider value={{
      attachmentsConfig,
      autoPopStorage, setAutoPopStorage,
      caseConfig,
      caseId, setCaseId,
      checkForGoogleAuth,
      contactEmailFields,
      copyItemCount, setCopyItemCount,
      copyStorage, setCopyStorage,
      deleteLinkStorage,
      detectedEntities,
      detectEntityIsRunning,
      errorLogs,
      exchangeIdSet,
      existingInteraction,
      existingLinks,
      externalId,
      externalIdField,
      googleAuthToken,
      googleAuthUser,
      handleOnChangeWithDetail,
      interactionId,
      isConnected,
      googleLogin,
      googleLogout,
      googleMessageId,
      isDraftMode,
      isExchangeSystem,
      isLoginScreenOpen, setIsLoginScreenOpen,
      isDebugPanelOpen, setIsDebugPanelOpen,
      linkStorage, setLinkStorage,
      logError,
      logScreenBottom,
      logType,
      msAuthUser,
      onLogCase,
      onLogData,
      orgInfo,
      parentIdMap, setParentIdMap,
      payload,
      pipeVersion, setPipeVersion,
      processMessage,
      quickAddMenu,
      recordTypeIdEmail,
      recordTypeIdMeeting,
      refreshSalesforceToken,
      relatedListConfigs, setRelatedListConfigs,
      requiredFields,
      retrieveExistingLinks,
      salesforceLoginUser,
      salesforceLogoutUser,
      salesforceInstance, setSalesforceInstance,
      salesforceUser,
      saveResultToast, setSaveResultToast,
      sections, setSections,
      selectAttachmentByDefault,
      screenType, setScreenType,
      showLoginSuccess,
      showUserMenu, setShowUserMenu,
      themeColors,
      userMenuAnchorEl, setUserMenuAnchorEl,
      valueStorage,
    }}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
